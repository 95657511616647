export default {
    signin: "登录",
    signup: "注册",
    time_limited: "限时",
    lable_recommended: "推荐",
    label_enterprise: "企业版",
    menu: {
        item_1: "AI翻译",
        item_2: "AI辅助翻译",
        item_3: "AI工具箱",
        item_4: "服务方案",
        item_5: "LanguageX社区",
        item_6: "一封信",
    },
    index: {
        banner_1_title: "为热爱语言的人而生",
        banner_1_btn: "立即体验",
        banner_1_btn_2: "加入等待列表",
        banner_2_title: "LanguageX斩获2022WMT国际机器翻译大赛",
        banner_2_btn: "查看详情",

        model_1_title: "为专业语言服务而生的AI翻译",
        model_1_desc: "找到全球最适合的翻译引擎，如果没有，就自己训练一个",
        model_1_item_1_title: "机器翻译优选",
        model_1_item_1_desc: "找到合适的引擎和找到合适的初稿译者一样重要。直观对比，术语自定义，让机翻更懂你",
        model_1_item_2_title: "训练自己的翻译引擎",
        model_1_item_2_desc: "从前你拥有记忆库，现在你拥有翻译引擎。一键上传，自动清洗语料、训练自己的专属引擎。",

        model_2_title: "AI辅助翻译",
        model_2_desc: "你可以专注自己热爱的语言，无需关注复杂的翻译技术",
        model_2_item_1_title: "交互式翻译",
        model_2_item_1_desc: "编辑器实时预览，AI交互式翻译和质量检查，你只需要专注于自己热爱的语言",
        model_2_item_2_title: "翻译项目管理",
        model_2_item_2_desc: "团队管理、多人协同，翻译流程自定义，任务批量分配",

        model_3_title: "AI工具箱",
        model_3_desc: "极简、智能、好用的AI翻译小工具",
        model_3_comesoon: "Coming Soon",
        model_3_item_1_title: "AI 润色",
        model_3_item_1_desc: "基于生成式 AI，你的随身母语润色专家",
        model_3_item_2_title: "语料对齐",
        model_3_item_2_desc: "业内领先的对齐算法，全新拖拽式对齐体验，AI让语料对齐更简单",
        model_3_item_3_title: "机翻检测",
        model_3_item_3_desc: "智能标记机翻痕迹，有效识别译文质量风险",
        model_3_item_4_title: "术语提取",
        model_3_item_4_desc: "智能提取批量文件术语，快速修改即可制作术语库",
        model_3_item_5_title: "QA检查",
        model_3_item_5_desc: "不止传统规则QA，更有基于语义理解的智能检查",
        model_3_item_6_title: "AI译配",
        model_3_item_6_desc: "一键翻译和配音，瞬间切换语言的AI魔法",
        model_3_item_7_title: "AI翻译评分",
        model_3_item_7_desc: "机翻译文智能评分，AI为你推荐参考翻译模型",

        model_4_title: "服务方案",
        model_4_tab_1: "会员",
        model_4_tab_2: "字符包",
        model_4_desc_1: "文档翻译、文本翻译、AI工具箱均按照原文字符数消耗流量。会员服务：购买日起一个月/半年/一年内有效，会员字符每月重置，不结转。字符包自购买日起一年内可用（",
        model_4_desc_link: "查看详细说明",
        model_4_desc_2: "）。活动解释权归LanguageX所有。",
        //feature_1
        model_4_item_1_title: "FREE",
        model_4_item_1_feature_1: "12款通用引擎",
        model_4_item_1_feature_2: "6款官方个性引擎",
        model_4_item_1_feature_3: "4款AI工具",
        model_4_item_1_feature_3_desc: "AI润色、机翻评分、语料对齐、大模型智能体翻译",
        // model_4_item_1_feature_4: "AI 润色",
        model_4_item_1_feature_5: "每月5万免费字符",
        model_4_item_1_feature_6: "文件大小10MB",
        model_4_item_1_feature_7: "AI译配免费试用次数",
        model_4_item_1_btn: "免费使用",
        //feature_2
        model_4_item_2_title: "PRO",
        model_4_item_2_feature_1: "所有Free版功能",
        model_4_item_2_feature_1_desc: "ChatGPT/Gemini/Kimi等6款大模型翻译",
        model_4_item_2_feature_2: "9款垂直领域引擎",
        model_4_item_2_feature_3: "所有引擎术语定制",
        model_4_item_2_feature_4: "创建自己的个性引擎",
        model_4_item_2_feature_5: "6款AI工具",
        model_4_item_2_feature_5_desc: "Al润色、语料对齐、机翻检测、AI翻译评分、AI译配（每月3次）、大模型智能体翻译",
        model_4_item_2_feature_6: "每月50万免费字符",
        model_4_item_2_feature_7: "文件大小50MB",
        model_4_item_2_feature_8: "支持PDF格式",
        model_4_item_2_price_1: "￥29",
        model_4_item_2_price_1_crossed: "￥59",
        model_4_item_2_price_1_expires: "月",
        model_4_item_2_price_2: "￥159",
        model_4_item_2_price_2_crossed: "￥329",
        model_4_item_2_price_2_expires: "半年",
        model_4_item_2_price_3: "￥299",
        model_4_item_2_price_3_crossed: "￥599",
        model_4_item_2_price_3_expires: "年",
        model_4_item_2_btn: "7天免费体验",
        //feature_3
        model_4_item_3_title: "企业版",
        model_4_item_3_feature_1: "所有PRO版功能",
        model_4_item_3_feature_2: "每月500万免费字符",
        model_4_item_3_feature_3: "全部AI工具（AI译配每月10次）",
        model_4_item_3_feature_4: "单个项目50文件",
        model_4_item_3_feature_5: "4个个性引擎",
        model_4_item_3_feature_6: "单文件大小100M",
        model_4_item_3_feature_7: "多达10名成员协作",
        model_4_item_3_feature_8: "项目任务管理",
        model_4_item_3_btn: "7天免费体验",
        model_4_item_3_price_1: "￥12000",
        model_4_item_3_price_1_crossed: "￥599",
        model_4_item_3_price_1_expires: "年",
        model_4_item_3_price_2: "￥23800",
        model_4_item_3_price_2_crossed: "￥5999",
        model_4_item_3_price_2_expires: "二年",
        model_4_item_3_price_3: "￥35600",
        model_4_item_3_price_3_crossed: "￥5999",
        model_4_item_3_price_3_expires: "三年",
        //feature_4
        model_4_item_4_title: "教育版",
        model_4_item_4_feature_1: "所有PRO版功能",
        model_4_item_4_feature_2: "每月200万免费字符",
        model_4_item_4_feature_3: "全部AI工具",
        model_4_item_4_feature_4: "4个个性引擎",
        model_4_item_4_feature_5: "更大文件",
        model_4_item_4_feature_6: "单个项目更多文件数",
        model_4_item_4_feature_7: "项目任务管理",
        model_4_item_4_feature_8: "多达50名学生人数",
        model_4_item_4_btn: "联系我们",

        model_4_char_btn_1: "立即购买",
        model_4_char_btn_2: "联系我们",

        model_4_char_1_title: "10万字符",
        model_4_char_1_price: "¥25元",
        model_4_char_1_desc: "相当于2.5元/万字符",
        model_4_char_2_title: "50万字符",
        model_4_char_2_price: "¥99元",
        model_4_char_2_desc: "相当于1.98元/万字符",
        model_4_char_3_title: "100万字符",
        model_4_char_3_price: "¥139元",
        model_4_char_3_desc: "相当于1.39元/万字符",
        model_4_char_4_title: "1000万字符",
        model_4_char_4_price: "¥1251元",
        model_4_char_4_desc: "相当于1.251元/万字符", 
        model_4_char_5_title: "一亿以上",    

        model_5_title: "用户评价",
        model_5_experience_1: "{year}年翻译经验",
        model_5_experience_2: "香港中文大学翻译技术教师",
        model_5_experience_3: "{year}年翻译项目管理经验",
        model_5_item_1_user: "Bobbie Chen",
        model_5_item_1_desc: "用户编辑界面简洁容易操作，交互式翻译能实时根据用户编辑内容调整句式，而不是单纯的译后编辑，节省了不少修改时间。综合考虑，LanguageX是市面上不可多得、新手小白友好的翻译辅助软件。",
        model_5_item_2_user: "老叮当",
        model_5_item_2_desc: "一款针对年轻译员打造的翻译软件，莫兰迪的配色，恰到好处的动画，防呆的操作逻辑，私人订制的小引擎，每天的翻译工作都像是在玩养成类游戏。我一直觉得未来的翻译软件除了功能强大，也要有温度，可成长，常用常新，现在它出现了——LanguageX！",
        model_5_item_3_user: "Flora Wang",
        model_5_item_3_desc: "我非常认同LanguageX“机器的工作归机器，人的工作归人”的理念。它更像是一位“知音搭档”，不仅能预处理机械重复的基础翻译，还能为译者提供应对复杂翻译任务的思路灵感，激发译者产出超越机器的高质量译文。它提供的“主流翻译引擎对比、交互翻译、AI润色”等众多功能让我眼前一亮。如果你还没找到一款“即刻上手、相见恨晚”的翻译工具，我推荐你试试“LanguageX”。",
        model_5_item_4_user: "胡可",
        model_5_item_4_desc: "对年轻译者、入门翻译技术的学生而言，LanguageX有三个独特的优势。第一，界面友好：LanguageX的界面交互清晰直观，降低了上手难度和学习成本；第二，功能全面：除记忆库与术语库外，机翻引擎结果对比、训练个性引擎等功能，可有效帮助用户提升工作效率。第三，适配度高：作为基于云端的平台，LanguageX摆脱了传统CAT工具对操作系统与设备的限制，更贴合年轻用户的使用场景与习惯。",
        model_5_item_5_user: "Sherry",
        model_5_item_5_desc: "系统界面一目了然，颜色清新、易于操作。实现了线上项目分配、校对、QA等流程，接入了各大外部引擎以及搜索功能，只需要一个链接即可实现随时随地办公。",
        model_5_item_6_user: "Vicky",
        model_5_item_6_desc: "LanguageX 引擎众多，还可以为自己的项目量身训练属于自己的引擎，使我们可以更好的精准管理各类型各领域的项目。工具齐全，项目全过程都有能用到的工具，翻译与科技结合，成为一站式项目翻译智能平台。",

        model_6_title: "加入我们",
        model_6_left_item_1: "我们是一群热爱语言和科技的人",
        model_6_left_item_2: "是语言与科技的“译者”",
        model_6_left_item_3: "是黑客与画家",
        model_6_left_item_4: "我们致力于为热爱语言者打造极致AI工具",
        model_6_right_item_1: "翻译是一门艺术",
        model_6_right_item_2: "译者是跨语言的作家",
        model_6_right_item_3: "让机器的工作归机器，人的工作归人",

        model_7_title: "我们的客户",

        footer_product: "产品",
        footer_product_1: "AI翻译",
        footer_product_2: "AI辅助翻译",
        footer_product_3: "AI工具箱",
        footer_product_4: "译者社区",
        footer_product_5: "翻译API",
        footer_help: "帮助中心",
        footer_help_1: "LanguageX是什么",
        footer_help_2: "AI辅助翻译指南",
        footer_help_3: "AI工具箱指南",
        footer_help_4: "LanguageX FAQ 常见问题",
        footer_help_5: "TMS企业版指南",
        footer_aboutus: "关于",
        footer_aboutus_1: "联系我们",
        footer_agreement: "用户协议",
        footer_agreement_1: "服务协议",
        footer_agreement_2: "隐私政策",
        footer_contact_1: "公众号",
        footer_contact_2: "用户交流群",
    }
}